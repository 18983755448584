import { signature } from './signature'
import { optionsText } from 'utils/order'
import { productNameById } from 'utils/product'
import dayjs from 'dayjs'

const ordersText = (orders: any[], products: any[]) => {
  const result = []
  for (const o of orders) {
    const options = optionsText(o, products)
    let t = `
注文の種別　　　　　：${o.repeat_id !== 0 ? 'リピート注文' : '新規注文'}
リピート元注文Ｎｏ．：${o.repeat_id !== 0 ? o.repeat_id : '--'}
ご注文Ｎｏ．　　　　：${o.order_id}
商品名　　　　　　　：${o.name}`
    if (o.order_type === 3) {
      t = `${t}
パターン名　　　　　：${o.estimate_pattern_name}
${o.specification}
      `
    } else {
      t = `${t}
材質　　　　　　　　：${productNameById(products, 'material', o.product_id)}
サイズ　　　　　　　：縦${o.size_y}㎜×横${o.size_x}㎜
枚数　　　　　　　　：${o.num}枚
発送予定　　　　　　　　：${
        o.delivery_date_option + o.delivery_date_product
      }営業日以内
[オプション加工]
${options}
      `
    }
    result.push(t)
  }
  return result.join('\n')
}

export const templates = (
  user: any,
  purchase: any,
  products: any[],
  order: any,
) => {
  const userName = () => {
    return [user.organization, user.family_name, user.given_name]
      .join(' ')
      .trim()
  }
  const options = optionsText(order, products)
  let amount = 0
  for (const o of purchase.purchase.orders) {
    if (o.product_id === 1) {
      // ポイント利用の計算
      amount = amount + o.point
    } else {
      amount = amount + o.amount_product + o.amount_option
    }
  }
  const purchaseDate = dayjs(purchase.purchase.created_at * 1000).format(
    'YYYY/MM/DD hh:mm:ss',
  )
  const orders = ordersText(purchase.purchase.orders, products)
  return {
    nyuko: {
      title: `【シール印刷のヒット・ラベルです】ご入稿のご案内`,
      body: `${userName()} 様
この度はシール印刷のヒット・ラベルをご利用いただき誠にありがとうございます。

${purchaseDate}にご注文いただきましたデータのご準備はいかがでしょうか。
現在、データがご入稿されていない状態ですので
ご入稿データのご準備が整い次第、ウェブ上よりご入稿いただくか
こちらのメールに返信という形でデータをお送りください。
なお、ご注文から2週間が経過いたしますと、自動的に注文はキャンセルとなります。
あらかじめご了承くださいませ。
ご不明な点等ございましたらご連絡ください。
何卒、よろしくお願いいたします。
================================================================
ご注文商品情報
================================================================
【注文詳細】
================================================================
${orders}
${signature}
  `,
    },
    bank: {
      title: `【シール印刷のヒット・ラベルです】ご入金のご案内`,
      body: `${userName()} 様
この度は、シール・ラベル印刷のヒット・ラベルでご注文いただきまして誠にありがとうございます。

お支払い方法につきまして、「銀行振込」のお客様にご案内をさせていただきます。
銀行振込でお支払いの場合は、「前払い」となります。
ご入金が弊社で確認がとれた翌日が「納期起算日」となり、データチェック作業・印刷工程へと進めさせていただく流れとなります。
また、請求書が必要な場合はマイページより請求書がダウンロードできますので
ご利用ください。
https://hitlabel.jp/mypage
なお、ご注文から2週間が経過いたしますと、自動的に注文はキャンセルとなります。
あらかじめご了承くださいませ。
================================================================
振込口座　※振込手数料はお客様負担でお願い致します。
================================================================
三菱UFJ銀行 新大阪駅前支店（店番 083）
【口座番号】 普通 0163833
【口座名義】 カ）アド バル
振込名義人を下記の仕様でご記入いただきますよう、ご協力をお願い致します。
『お支払い番号』+『お客様名』
================================================================
■お客様情報 ————————————————————————————————
お客様名 ： ${userName()} 様
お支払い番号 ：${purchase.purchase.purchase_id}
お振込金額 ：${amount}円
——————————————————————————————————————
※このメールと行き違いですでに、ご入金いただいている場合は、
何卒ご容赦ください。
何かご不明な点などございましたら、お気軽にお問合わせ下さい。
お手数ではございますが、何卒、よろしくお願い致します。
${signature}
  `,
    },
    vbank: {
      title: `【シール印刷のヒット・ラベルです】ご入金のご案内`,
      body: `${userName()} 様
この度は、シール・ラベル印刷のヒット・ラベルでご注文いただきまして誠にありがとうございます。
お支払い方法につきまして、「銀行振込（バーチャル口座）」のお客様にご案内をさせていただきます。
銀行振込（バーチャル口座）でお支払いの場合は、「前払い」となります。
ご入金が弊社で確認がとれた翌日が「納期起算日」となり、データチェック作業・印刷工程へと進めさせていただく流れとなります。
お支払いに関しては、お送りしておりますバーチャル口座へのお振込となりますので
メールのご確認をお願い致します。
■お客様情報 ————————————————————————————————
お客様名 ： ${userName()} 様
お支払い番号 ：${purchase.purchase.purchase_id}
お振込金額 ：${amount}円
——————————————————————————————————————
※このメールと行き違いですでに、ご入金いただいている場合は、
何卒ご容赦ください。
また、ご注文日より２週間ご入金の確認が取れませんと
自動的にキャンセルとなりますので、ご注意ください。
その他ご不明な点などございましたら、お気軽にお問合わせ下さい。
お手数ではございますが、何卒、よろしくお願い致します。
${signature}
  `,
    },
    cutlineService: {
      title: `【シール印刷のヒット・ラベルです】カットラインご確認のお願い`,
      body: `${userName()} 様

※現在納期は確定していない状況です。下記内容をご確認の上、必ずご連絡をお願いいたします。

ご入稿のデータをもとに、カットラインを2種類作成いたしましたので、
どちらかお選びいただくか、サイズ・形状ともに問題ないかご確認ください。

添付画像がご確認いただけない場合は、
ヒット・ラベルのマイページ上からもご確認いただけます。
https://hitlabel.jp/mypage

================================================================
ご注文商品情報
================================================================

ご注文Ｎｏ．　　　　：${order.order_id}
商品名　　　　　　　：${order.name}
材質　　　　　　　　：${productNameById(products, 'material', order.product_id)}
サイズ　　　　　　　：縦${order.size_y}㎜×横${order.size_x}㎜
枚数　　　　　　　　：${order.num}枚
発送予定　　　　　　：${
        order.delivery_date_option + order.delivery_date_product
      }営業日以内

[オプション加工]
${options}

修正が必要な場合はお申し付けください。

何卒、よろしくお願いいたします。

${signature}`,
    },
    wrongData: {
      title: `【シール印刷のヒット・ラベルです】入稿データの不足・不備について`,
      body: `${userName()} 様

この度はシール印刷のヒット・ラベルをご利用いただき誠にありがとうございます。

${purchaseDate}にご注文分につきまして、
下記の通り不足データがございます。
不足：画像２点

入稿データのご準備が整い次第、ウェブ上よりご入稿いただくか、
こちらのメールに返信という形でデータをお送りください。

なお、ご注文から2週間が経過いたしますと、自動的に注文はキャンセルとなります。
あらかじめご了承くださいませ。

添付画像がご確認いただけない場合は、
ヒット・ラベルのマイページ上からもご確認いただけます。
https://hitlabel.jp/mypage

ご不明な点等ございましたらご連絡ください。
何卒、よろしくお願いいたします。
================================================================
ご注文商品情報
================================================================

ご注文Ｎｏ．　　　　：${order.order_id}
商品名　　　　　　　：${order.name}
材質　　　　　　　　：${productNameById(products, 'material', order.product_id)}
サイズ　　　　　　　：縦${order.size_y}㎜×横${order.size_x}㎜
枚数　　　　　　　　：${order.num}枚
発送予定　　　　　　：${
        order.delivery_date_option + order.delivery_date_product
      }営業日以内

[オプション加工]
${options}

修正が必要な場合はお申し付けください。

何卒、よろしくお願いいたします。

${signature}`,
    },
    dataCheck: {
      title: `【シール印刷のヒット・ラベルです】ご入稿データの確認です`,
      body: `${userName()} 様

この度はシール印刷のヒット・ラベルをご利用いただき誠にありがとうございます。

${purchaseDate}にご注文分につきまして、
下記の通りご確認いただきたい点がございます。



確認の結果はこちらのメールに返信という形でご連絡をお願いいたします。
修正が必要な場合はお申し付けください。

なお、ご注文から2週間が経過いたしますと、自動的に注文はキャンセルとなります。
あらかじめご了承くださいませ。

添付画像がご確認いただけない場合は、
ヒット・ラベルのマイページ上からもご確認いただけます。
https://hitlabel.jp/mypage

ご不明な点等ございましたらご連絡ください。
何卒、よろしくお願いいたします。
================================================================
ご注文商品情報
================================================================

ご注文Ｎｏ．　　　　：${order.order_id}
商品名　　　　　　　：${order.name}
材質　　　　　　　　：${productNameById(products, 'material', order.product_id)}
サイズ　　　　　　　：縦${order.size_y}㎜×横${order.size_x}㎜
枚数　　　　　　　　：${order.num}枚
発送予定　　　　　　：${
        order.delivery_date_option + order.delivery_date_product
      }営業日以内

[オプション加工]
${options}

修正が必要な場合はお申し付けください。

何卒、よろしくお願いいたします。

${signature}`,
    },
  }
}
