import axios from 'axios'
import Loading from 'components/Loading'
import * as React from 'react'
import { useAuth0 } from 'react-auth0-spa'
import { useParams } from 'react-router-dom'
// import { tidyUpOrderStatuses } from 'utils/orderstatuses'
import { tidyUpProducts, productNameById } from 'utils/product'
import dayjs from 'dayjs'
import { paymentName } from 'utils'
import { QRCodeSVG } from 'qrcode.react'
import maruhi from 'images/kojin.png'
import { Helmet, HelmetProvider } from 'react-helmet-async'

type Params = {
  id: string
}

const ViewOrder: React.FC = () => {
  const { id } = useParams<Params>()
  const { getTokenSilently } = useAuth0()
  const [token, setToken] = React.useState<string | undefined>('')
  const [order, setOrder] = React.useState<any | null>(null)
  // const [sheetNum, setSheetNum] = React.useState(0)
  const [purchase, setPurchase] = React.useState<any | null>(null)
  const [user, setUser] = React.useState<any | null>(null)
  const [products, setProducts] = React.useState<any | null>(null)
  // const [orderStatuses, setOrderStatuses] = React.useState<any[] | null>(null)
  const [sampleImage, setSampleImage] = React.useState<string>('')
  const [loading, setLoading] = React.useState<boolean>(false)

  // トークンセット
  React.useEffect(() => {
    getTokenSilently().then((t: string | undefined) => {
      setToken(t)
    })
  }, [getTokenSilently])

  const downloadSample = React.useCallback(() => {
    if (!token) {
      return
    }
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/orders/${id}/sample`,
      )
      .then((res) => {
        if (res.data.data === null) {
          setSampleImage(`madanai`)
          return
        }
        setSampleImage(`data:image/jpg;base64,${res.data.data}`)
      })
      .catch((err) => {
        setSampleImage(`madanai`)
      })
  }, [id, token])

  // React.useEffect(() => {
  //   if (!order) return
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_API_ENDPOINT}/api/v2/sheet_num/${order.product_id}/${order.size}/${order.num}`,
  //     )
  //     .then((res) => {
  //       setSheetNum(res.data.data)
  //     })
  // }, [order])

  const getOrder = React.useCallback(() => {
    if (!token) {
      return
    }
    axios.defaults.headers.common['Authorization'] = token
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/orders/${id}`)
      .then((res) => {
        setOrder(res.data.data)
        setLoading(false)
      })
    downloadSample()
  }, [id, token, downloadSample])

  const getPurchase = React.useCallback(() => {
    if (token === '' || !order) {
      return
    }
    axios.defaults.headers.common['Authorization'] = token
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/purchase-orders/${order.purchase_id}`,
      )
      .then((res) => {
        setPurchase(res.data.data)
        setLoading(false)
      })
  }, [order, token])

  const getUser = React.useCallback(() => {
    if (token === '' || !purchase) {
      return
    }
    axios.defaults.headers.common['Authorization'] = token
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/users/${purchase.purchase.user_id}`,
      )
      .then((res) => {
        setUser(res.data.data)
        setLoading(false)
      })
  }, [purchase, token])

  const getUploadedFiles = React.useCallback(() => {
    if (!token) {
      return
    }
    axios.defaults.headers.common['Authorization'] = token
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/orders/${id}/uploaded-files`,
      )
      .then((res) => {
        setLoading(false)
      })
  }, [token, id])

  // 注文情報
  React.useEffect(() => {
    getOrder()
  }, [getOrder])

  // 支払情報
  React.useEffect(() => {
    getPurchase()
  }, [getPurchase])

  // お客様情報, 商品情報, 注文ステータス
  React.useEffect(() => {
    if (token === '' || !purchase) {
      return
    }
    axios.defaults.headers.common['Authorization'] = token
    // お客様情報
    getUser()
    // 商品情報
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/products`)
      .then((res) => {
        const tidied = tidyUpProducts(res.data.data)
        setProducts(tidied)
      })
    // // 注文ステータス
    // axios
    //   .get(`${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/orderstatuses`)
    //   .then((res) => {
    //     const tidied = tidyUpOrderStatuses(res.data.data)
    //     setOrderStatuses(tidied)
    //   })
    getUploadedFiles()
  }, [purchase, token, getUser, getUploadedFiles])

  if (!order || !purchase || !user) {
    return <Loading text="注文情報読み込み中..." />
  }
  if (!products) {
    return <Loading text="商品情報読み込み中..." />
  }
  if (loading) {
    return <Loading text="データ更新中..." />
  }

  let estimateData: any
  try {
    estimateData = JSON.parse(order.estimate_data)
  } catch {}

  // const repeatHistories = prepareRepeatHistories(order)

  const p = purchase.purchase
  // prettier-ignore
  const data = {
    createdAt: dayjs(new Date(p.created_at * 1000)).format('YYYY/MM/DD HH:mm:ss'),
    updatedAt: dayjs(new Date(order.updated_at * 1000)).format('YYYY/MM/DD HH:mm:ss'),
    order: [
      ['材質', productNameById(products, "material", order.product_id)],
      ['接着糊', productNameById(products, "adhesive", order.adhesive)],
      ['ラミ', productNameById(products, "lamination", order.lamination)],
      ['納品形態', productNameById(products, "deliveryForm", order.delivery_form)],
      ['裏スリット', productNameById(products, "slit", order.slit)],
      ['裏面印刷', productNameById(products, "backSide", order.back_side)],
      ['ﾎﾜｲﾄｲﾝｸ', productNameById(products, "whiteInk", order.white_ink)],
      ['ﾊｰﾌｶｯﾄ', productNameById(products, "halfcut", order.halfcut)],
      ['カットパス', productNameById(products, "cutpath", order.cutpath)],
      ['控え発送', productNameById(products, "sendCopy", order.send_copy)],
      ['ﾘﾋﾟｰﾄ元ID', `${order.repeat_id}`],
      ['', ''],
      ['タテ', order.size_y + '㎜'],
      ['ヨコ', order.size_x + '㎜'],
      ['サイズ', order.size + '㎠'],
      ['個包装', productNameById(products, "individualPackaging", order.individual_packaging)],
      // ['ステータス', orderStatuses?.filter((v) => { return order.status === v.value })[0].name],
    ],
    user: [
      ['ID', user.id],
      ['E-mail', user.email],
      ['組織名', user.organization],
      ['氏名', `${user.family_name} ${user.given_name}`],
      ['カナ', `${user.family_kana} ${user.given_kana}`],
      ['郵便番号', ('0000000' + Number(user.postal_code)).slice(-7)],
      ['住所', `${user.address_level1}${user.address_level2}${user.address_line1}${user.address_line2}`],
      ['電話番号', user.tel],
      ['FAX', user.fax],
      ['緊急連絡先', user.emergency_tel],
      ['保有ポイント', user.holding_point],
    ],
    shippingDest: [
      ['組織名', p.dest_organization],
      ['氏名', `${p.dest_family_name} ${p.dest_given_name}`],
      ['電話番号', p.dest_tel],
      ['郵便番号', ('0000000' + Number(p.dest_postal_code)).slice(-7)],
      ['住所', `${p.dest_address_level1}${p.dest_address_level2}${p.dest_address_line1}${p.dest_address_line2}`],
    ],
    shippingSrc: [
      ['組織名', p.src_organization],
      ['氏名', `${p.src_family_name} ${p.src_given_name}`],
      ['電話番号', p.src_tel],
      ['郵便番号', ('0000000' + Number(p.src_postal_code)).slice(-7)],
      ['住所', `${p.src_address_level1}${p.src_address_level2}${p.src_address_line1}${p.src_address_line2}`],
    ],
    paymentMethod: paymentName(p.method),
    subtotal: p.total_amount - p.commission,
    usedPoint: Math.abs(p.used_point),
    commission: p.commission,
    total: p.total_amount + p.used_point,
    deliveryTime: p.delivery_time.toUpperCase(),
    shippingDate: order.shipping_date !== 0 ? dayjs(new Date(order.shipping_date * 1000)).format('YYYY/MM/DD') : '未設定',
    note: p.note,
  }

  return (
    <main className="w-[640px] max-w-[640px] overflow-hidden mx-auto relative">
      <HelmetProvider>
        <Helmet>
          <title>{`【HitLabel - ※個人情報※】${order.order_id}`}</title>
        </Helmet>
      </HelmetProvider>
      <div
        className="flex items-center text-center text-sm font-bold absolute top-0 right-0"
        style={{ color: '#cc131b' }}
      >
        <img
          src={maruhi}
          alt="社外秘"
          width={200}
          height={20}
          className="mr-px"
        />
      </div>
      <div className="container mx-auto mb-40 mt-8">
        <div className="flex justify-between items-start">
          <div>
            <h1 className="items-baseline">
              <span className="text-2xl font-bold tracking-wide">
                {order.order_id}
              </span>
              <span className="text-2xl font-bold tracking-tighter px-1">
                - {data.shippingDate}
              </span>
              <span>発送予定</span>
            </h1>
            <div className="text-xs flex">
              <div className="mr-1">注文日時:</div>
              <div>{data.createdAt}</div>
              <div className="px-2"> | </div>
              <div className="mr-1">更新日時:</div>
              <div>{data.updatedAt}</div>
            </div>
            <div className="flex items-end mt-2 justify-start">
              <span>{order.name}</span>
              <span className="text-lg ml-8 shrink-0">{order.num}枚</span>
              {order.send_copy === 136 && (
                <span className="text-lg shrink-0 ml-1">+ 控え</span>
              )}
              {order.individual_packaging !== 262 &&
                order.individual_packaging !== 263 && (
                  <span className="text-lg shrink-0 ml-1">+ 個包装</span>
                )}
              {order.can_example_use && (
                <div className="text-sm shrink-0 rounded mx-2 py-1 px-2 bg-zinc-200">
                  紹介OK
                </div>
              )}
              {order.free_format_option_title !== '' && (
                <div className="text-sm shrink-0 rounded mx-2 py-1 px-2 bg-zinc-200">
                  追ｵﾌﾟ有
                </div>
              )}
            </div>
          </div>
          <QRCodeSVG
            value={order.order_id.toString()}
            size={96}
            bgColor={'#ffffff'}
            fgColor={'#000000'}
            level={'H'}
            includeMargin={false}
          />
        </div>
        <div className="flex my-2">
          <div className="flex flex-col justify-start flex-1 w-full basis-1/2">
            <div className="w-full">
              <div className="border w-full aspect-square p-2 flex items-center justify-center">
                <img
                  src={sampleImage}
                  alt="商品参考画像"
                  className="w-full h-auto"
                />
              </div>
            </div>
            <div className="mt-2 h-full">
              <div className="h-full flex flex-col">
                <div className="flex">
                  <div className="flex-1 flex flex-col items-center">
                    <div className="text-sm">丁数</div>
                    <div className="border-t border-l border-b w-full h-[64px]"></div>
                  </div>
                  <div className="flex-1 flex flex-col items-center">
                    <div className="text-sm">シート数</div>
                    <div className="border-t border-l border-b w-full h-[64px]"></div>
                  </div>
                  <div className="flex-1 flex flex-col items-center">
                    <div className="text-sm">出来高</div>
                    <div className="border-t border-l border-b border-r w-full h-[64px]"></div>
                  </div>
                </div>
                <div className="pt-4 grow">
                  <div className="border h-full p-1">
                    <div className="text-xs text-zinc-500">メモ</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 basis-1/2 text-xs pl-2 break-all">
            <div>
              <h2 className="text-sm font-bold text-center mt-4 mb-2">
                お客様情報
              </h2>
              <table>
                {data.user.map((u) => {
                  return (
                    <tr key={u[0]}>
                      <th className="whitespace-nowrap text-right">{u[0]}:</th>
                      <td className="flex-1 shrink-1 pl-2">{u[1]}</td>
                    </tr>
                  )
                })}
              </table>
            </div>
            <div className="mb-2">
              <h3 className="text-center font-bold mb-2">納品先</h3>
              <table>
                {data.shippingDest.map((s) => {
                  return (
                    <tr key={s[0]}>
                      <th className="whitespace-nowrap text-right">{s[0]}:</th>
                      <td className="flex-1 shrink-1 pl-2">{s[1]}</td>
                    </tr>
                  )
                })}
              </table>
            </div>
            <div className="mb-2">
              <h3 className="text-center font-bold mb-2">荷主</h3>
              <table>
                {data.shippingSrc.map((s) => {
                  return (
                    <tr key={s[0]}>
                      <th className="whitespace-nowrap text-right">{s[0]}:</th>
                      <td className="flex-1 shrink-1 pl-2">{s[1]}</td>
                    </tr>
                  )
                })}
              </table>
            </div>
            <div>
              <h3 className="text-center font-bold mb-2">その他</h3>
              <table>
                <tr>
                  <th className="whitespace-nowrap text-right">支払い方法:</th>
                  <td className="flex-1 shrink-1 pl-2">{data.paymentMethod}</td>
                </tr>
                <tr>
                  <th className="whitespace-nowrap text-right">
                    商品代金合計:
                  </th>
                  <td className="flex-1 shrink-1 pl-2">{data.subtotal}</td>
                </tr>
                <tr>
                  <th className="whitespace-nowrap text-right">
                    ポイント利用数:
                  </th>
                  <td className="flex-1 shrink-1 pl-2">{data.usedPoint}</td>
                </tr>
                <tr>
                  <th className="whitespace-nowrap text-right">代引手数料:</th>
                  <td className="flex-1 shrink-1 pl-2">{data.commission}</td>
                </tr>
                <tr>
                  <th className="whitespace-nowrap text-right">支払金額:</th>
                  <td className="flex-1 shrink-1 pl-2">{data.total}</td>
                </tr>
                <tr>
                  <th className="whitespace-nowrap text-right">時間指定:</th>
                  <td className="flex-1 shrink-1 pl-2">{data.deliveryTime}</td>
                </tr>
                <tr>
                  <th className="whitespace-nowrap text-right">備考:</th>
                  <td className="flex-1 shrink-1 pl-2">{data.note}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div className="flex flex-col text-xs">
          <div>
            {estimateData && estimateData.child ? (
              <>
                <h2 className="text-sm font-bold text-center mb-2">見積仕様</h2>
                <div className="flex -m-1 justify-between">
                  <div className="p-1">
                    <div>
                      <span>見積番号: </span>
                      <span>
                        <a
                          href={`/estimates/${estimateData.estimate.estimateNumber}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-700 hover:underline hover:text-blue-900"
                        >
                          {estimateData.estimate.estimateNumber}
                        </a>
                      </span>
                    </div>
                    <div>
                      <span>見積子番号: </span>
                      <span>{estimateData.child.no}</span>
                    </div>
                    <div>
                      <span>パターン名: </span>
                      <span>{estimateData.child.name}</span>
                    </div>
                    <div>
                      <span>請求金額: </span>
                      <span>
                        {estimateData.child.amount_with_tax}円(内消費税:
                        {estimateData.child.tax}円)
                      </span>
                    </div>
                  </div>
                  <div className="p-1">
                    <span className="whitespace-pre">
                      {estimateData.child.main.specification}
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <>
                <h2 className="text-sm font-bold text-center mb-2">仕様</h2>
                <div className="grid grid-cols-3">
                  {data.order.map((o) => {
                    return (
                      <div key={o[0]} className="flex items-end px-1 py-px">
                        <span className="text-[8px] border-b py-px px-1 shrink-0 font-bold w-[60px] bg-zinc-100 h-full">
                          {o[0]}
                        </span>
                        {['あり', '希望する'].includes(o[1]) ||
                        o[1].includes('シール貼り') ? (
                          <span className="border-b py-px px-1 shrink-1 grow h-full font-bold text-red-500">
                            {o[1]}
                          </span>
                        ) : (
                          <span className="border-b py-px px-1 shrink-1 grow h-full">
                            {o[1]}
                          </span>
                        )}
                      </div>
                    )
                  })}
                </div>
                {order.free_format_option_title ? (
                  <div className="text-xs">
                    <div className="flex gap-1 p-1">
                      <div>追加オプション</div>
                      <div>:</div>
                      <div>{order.free_format_option_title}</div>
                    </div>
                    <div className="p-1">{order.free_format_option_body}</div>
                    <div className="p-1">
                      追加オプション料金: {order.free_format_option_amount}円
                    </div>
                  </div>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
    </main>
  )
}

// const prepareRepeatHistories = (order: any) => {
//   if (order.repeat_id === 0) {
//     return
//   }
//   order.repeatHistories.sort((prev: any, cur: any) => {
//     const prevTime = new Date(prev.orderedAt).getTime()
//     const curTime = new Date(cur.orderedAt).getTime()
//     return curTime - prevTime
//   })
//   return order.repeatHistories
// }

export default ViewOrder
