import axios from 'axios'
import NavBar from 'components/NavBar'
import Input from 'components/Form/Input'
import Loading from 'components/Loading'
import SampleImage from 'components/SampleImage'
import UserData from 'components/UserData'
import type { FieldProps } from 'formik'
import { Formik, Form, Field } from 'formik'
import * as React from 'react'
import { useAuth0 } from 'react-auth0-spa'
import { useParams } from 'react-router-dom'
import { tidyUpOrderStatuses } from 'utils/orderstatuses'
import { tidyUpProducts } from 'utils/product'
import { impositionDataString } from 'utils/order'
import { getFormSetting } from './form-setting'
import PurchaseTable from './purchase'
import UserTable from './user'
import { Controller } from './controller'
import { Datachecked } from './datacheck'
import { Shipment } from './shipment'
import { DoneAgain } from './done-again'
import { DeliveryInfo } from './delivery-info'
import { UserInfo } from './user-info'
import { SendMail, SendMailLog } from './send-mail'
import { Memo } from './memo'
import { Yupack } from './yupack'
import { ShippedMultiple } from './shipped'
import { DeliveredMultiple } from './delivered'
import { PrintPage } from './print'
import { QR } from 'components/QR'
import { saveAs } from 'file-saver'
import dayjs from 'dayjs'
import { OutsourceMail } from 'components/OutsourceMail'

type Params = {
  id: string
}

const Order: React.FC = () => {
  const { id } = useParams<Params>()
  const { getTokenSilently } = useAuth0()
  const [token, setToken] = React.useState<string | undefined>('')
  const [order, setOrder] = React.useState<any | null>(null)
  const [sheetNum, setSheetNum] = React.useState(0)
  const [purchase, setPurchase] = React.useState<any | null>(null)
  const [user, setUser] = React.useState<any | null>(null)
  const [products, setProducts] = React.useState<any | null>(null)
  const [orderStatuses, setOrderStatuses] = React.useState<any[] | null>(null)
  const [sampleImage, setSampleImage] = React.useState<string>('')
  const [loading, setLoading] = React.useState<boolean>(false)
  const [popup, setPopup] = React.useState<string>('')
  const [impositionStr, setImpositionStr] = React.useState<string>('')
  const [impositionCopied, setImpositionCopied] = React.useState<boolean>(false)
  const [sendmailLog, setSendmailLog] = React.useState([])

  const handleDownloadInvoice = React.useCallback(() => {
    setLoading(true)
    axios(
      `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/purchases/${purchase.purchase.id}/download-invoice`,
      {
        method: 'get',
        responseType: 'arraybuffer',
      },
    )
      .then((res) => {
        const blob: Blob = new Blob([res.data], {
          type: 'application/pdf;charset=utf-8',
        })
        saveAs(blob, `${purchase.purchase.purchase_id}.pdf`)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        alert('処理に失敗しました')
      })
  }, [purchase])

  const handleIssueReceipt = React.useCallback(() => {
    const to = window.prompt(
      '領収書を強制発行してメールでお客様に送信します。敬称を含めて宛名を入力してください',
    )
    if (to === null || to === '') {
      return
    }
    setLoading(true)
    axios(
      `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/purchases/${purchase.purchase.id}/issue-receipt`,
      {
        method: 'post',
        data: {
          to,
        },
      },
    )
      .then((res) => {
        alert('領収書を発行しました')
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        alert('処理に失敗しました')
      })
  }, [purchase])

  // トークンセット
  React.useEffect(() => {
    getTokenSilently().then((t: string | undefined) => {
      setToken(t)
    })
  }, [getTokenSilently])

  const downloadSample = React.useCallback(() => {
    if (!token) {
      return
    }
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/orders/${id}/sample`,
      )
      .then((res) => {
        if (res.data.data === null) {
          setSampleImage(`madanai`)
          return
        }
        setSampleImage(`data:image/jpg;base64,${res.data.data}`)
      })
      .catch((err) => {
        setSampleImage(`madanai`)
      })
  }, [id, token])

  React.useEffect(() => {
    if (!order) return
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/v2/sheet_num/${order.product_id}/${order.size}/${order.num}`,
      )
      .then((res) => {
        setSheetNum(res.data.data)
      })
  }, [order])

  const getOrder = React.useCallback(() => {
    if (!token) {
      return
    }
    axios.defaults.headers.common['Authorization'] = token
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/orders/${id}`)
      .then((res) => {
        setOrder(res.data.data)
        setLoading(false)
      })
    downloadSample()
  }, [id, token, downloadSample])

  const getPurchase = React.useCallback(() => {
    if (token === '' || !order) {
      return
    }
    axios.defaults.headers.common['Authorization'] = token
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/purchase-orders/${order.purchase_id}`,
      )
      .then((res) => {
        setPurchase(res.data.data)
        setLoading(false)
      })
  }, [order, token])

  const getUser = React.useCallback(() => {
    if (token === '' || !purchase) {
      return
    }
    axios.defaults.headers.common['Authorization'] = token
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/users/${purchase.purchase.user_id}`,
      )
      .then((res) => {
        setUser(res.data.data)
        setLoading(false)
      })
  }, [purchase, token])

  const getSendmailLog = React.useCallback(() => {
    if (!token) {
      return
    }
    axios.defaults.headers.common['Authorization'] = token
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/purchases/${purchase.purchase.purchase_id}/send-mail-log`,
      )
      .then((res) => {
        setSendmailLog(res.data.data)
        setLoading(false)
      })
  }, [token, purchase])

  // 注文情報
  React.useEffect(() => {
    getOrder()
  }, [getOrder])

  // 支払情報
  React.useEffect(() => {
    getPurchase()
  }, [getPurchase])

  // お客様情報, 商品情報, 注文ステータス
  React.useEffect(() => {
    if (token === '' || !purchase) {
      return
    }
    axios.defaults.headers.common['Authorization'] = token
    // お客様情報
    getUser()
    // メール送信履歴
    getSendmailLog()
    // 商品情報
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/products`)
      .then((res) => {
        const tidied = tidyUpProducts(res.data.data)
        setProducts(tidied)
      })
    // 注文ステータス
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/orderstatuses`)
      .then((res) => {
        const tidied = tidyUpOrderStatuses(res.data.data)
        setOrderStatuses(tidied)
      })
  }, [purchase, token, getUser, getSendmailLog])

  React.useEffect(() => {
    setImpositionStr(impositionDataString(order, user, products))
  }, [order, user, products])

  if (!order || !purchase || !user) {
    return <Loading text="注文情報読み込み中..." />
  }
  if (!products) {
    return <Loading text="商品情報読み込み中..." />
  }
  if (loading) {
    return <Loading text="データ更新中..." />
  }

  const formSettings = getFormSetting(products, orderStatuses, order)

  const handleDrop = (acceptedFiles: File[]) => {
    const formData = new FormData()
    formData.append('file', acceptedFiles[0])
    axios(
      `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/orders/${order.order_id}/sample`,
      {
        method: 'put',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      },
    ).then((res) => {
      if (res.status === 200) {
        downloadSample()
      }
    })
  }

  const closePopup = () => {
    setPopup('')
  }

  const sendShipped = () => {
    setLoading(true)
    if (
      order.delivery_provider === '' ||
      order.delivery_provider === '未配送' ||
      order.tracking_number === ''
    ) {
      alert('配送情報を確認してください')
      setLoading(false)
      setPopup('')
      return
    }
    axios(
      `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/orders/${id}/ship`,
      {
        method: 'put',
      },
    )
      .then((res) => {
        getOrder()
        setPopup('')
      })
      .catch((err) => {
        setLoading(false)
        alert('処理に失敗しました')
        setPopup('')
      })
  }

  const sendDatachecked = () => {
    setLoading(true)
    if (order.shipping_date === 0) {
      alert('配送予定日を確認してください')
      setLoading(false)
      setPopup('')
      return
    }
    axios(
      `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/orders/${id}/datachecked`,
      {
        method: 'put',
      },
    )
      .then((res) => {
        getOrder()
        setPopup('')
      })
      .catch((err) => {
        setLoading(false)
        alert('処理に失敗しました')
        setPopup('')
      })
  }

  const setShippedMultiple = () => {
    setLoading(true)
    axios(
      `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/orders/${id}/shipped-together`,
      {
        method: 'put',
      },
    )
      .then((res) => {
        getOrder()
        setPopup('')
      })
      .catch((err) => {
        setLoading(false)
        alert('処理に失敗しました')
        setPopup('')
      })
  }

  const setDeliveredMultiple = () => {
    setLoading(true)
    axios(
      `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/orders/${id}/delivered-together`,
      {
        method: 'put',
      },
    )
      .then((res) => {
        getOrder()
        setPopup('')
      })
      .catch((err) => {
        setLoading(false)
        alert('処理に失敗しました')
        setPopup('')
      })
  }

  const sendOrderConfirmation = () => {
    if (!purchase) {
      alert('支払い情報が読み込めていません')
      return
    }
    setLoading(true)
    axios(
      `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/purchases/${purchase.purchase.id}/order-confirmation`,
      {
        method: 'put',
      },
    )
      .then((res) => {
        setLoading(false)
        setPopup('')
      })
      .catch((err) => {
        setLoading(false)
        alert('処理に失敗しました')
        setPopup('')
      })
  }

  const changeDeliveryInfo = (values: any) => {
    if (!purchase) {
      alert('支払い情報が読み込めていません')
      return
    }
    setLoading(true)
    values.dest_postal_code = Number(
      String(values.dest_postal_code).replace(/-/g, ''),
    )
    values.src_postal_code = Number(
      String(values.src_postal_code).replace(/-/g, ''),
    )
    axios(
      `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/purchases/${purchase.purchase.id}/change-delivery-info`,
      {
        method: 'put',
        data: values,
      },
    )
      .then((res) => {
        getPurchase()
        setPopup('')
      })
      .catch((err) => {
        setLoading(false)
        alert('処理に失敗しました')
        setPopup('')
      })
  }

  const changeCustomerInfo = (values: any) => {
    if (!user) {
      alert('ユーザー情報が読み込めていません')
      return
    }
    setLoading(true)
    axios(
      `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/users/${user.id}`,
      {
        method: 'put',
        data: values,
      },
    )
      .then((res) => {
        getUser()
        setPopup('')
      })
      .catch((err) => {
        setLoading(false)
        alert('処理に失敗しました')
        setPopup('')
      })
  }

  const sendMail = (data: FormData) => {
    if (!purchase) {
      alert('支払い情報が読み込めていません')
      return
    }
    setLoading(true)
    axios(`${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/send-mail`, {
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: data,
    })
      .then((res) => {
        if (res.status === 200) {
          setLoading(false)
          setPopup('')
          getSendmailLog()
        }
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
        alert('処理に失敗しました。南まで確認をお願いします')
        setPopup('')
        getSendmailLog()
      })
  }

  let estimateData: any
  try {
    estimateData = JSON.parse(order.estimate_data)
  } catch {}

  const repeatHistories = prepareRepeatHistories(order)

  const clickImpositionData = () => {
    navigator.clipboard.writeText(impositionStr).then(
      () => {
        setImpositionCopied(true)
        window.setTimeout(() => {
          setImpositionCopied(false)
        }, 2000)
      },
      (err) => {
        window.alert(err)
      },
    )
  }

  return (
    <>
      <header>
        <NavBar />
      </header>
      <div className="container mx-auto mb-40 print:hidden">
        <h1 className="flex items-baseline">
          <span className="text-4xl font-bold my-2">注文情報詳細</span>
          <a
            href="#section-memo"
            className="px-4 hover:underline text-blue-500 hover:text-blue-600 active:text-blue-700"
          >
            メモ
          </a>
          <a
            href="#section-send-mail-log"
            className="px-4 hover:underline text-blue-500 hover:text-blue-600 active:text-blue-700"
          >
            メール送信履歴
          </a>
          <a
            href={`/orders/${id}/view`}
            className="px-4 hover:underline text-blue-500 hover:text-blue-600 active:text-blue-700"
          >
            印刷用
          </a>
          <a
            href={`${process.env.REACT_APP_M_HITWORKS}/${id}`}
            className="px-4 hover:underline text-blue-500 hover:text-blue-600 active:text-blue-700"
            target="_blank"
            rel="noopener noreferrer"
          >
            ヒットラベル進行管理
          </a>
          <OutsourceMail
            data={{
              order: order,
              user: user,
              purchase: purchase.purchase,
              products: products,
              estimateData: estimateData,
            }}
          />
        </h1>
        <h2 className="text-3xl font-bold">注文情報</h2>
        <div className="flex flex-col">
          <div className="flex">
            <Formik
              initialValues={order}
              onSubmit={(values, actions) => {
                setLoading(true)
                const formData = new FormData()
                values.tracking_number = values.tracking_number.trim()
                const jsonStr = JSON.stringify(values)
                formData.append('json', jsonStr)
                axios(
                  `${process.env.REACT_APP_API_ENDPOINT}/api/v2/admin/orders/${id}`,
                  {
                    method: 'put',
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                    data: formData,
                  },
                )
                  .then((res) => {
                    getOrder()
                  })
                  .catch((err) => {
                    setLoading(false)
                    alert(
                      '更新処理に失敗しました\n' +
                        '理由:価格計算出来ない値が入力されたなど',
                    )
                  })
              }}
            >
              {(props) => (
                <Form className="flex-1">
                  <div className="flex flex-wrap">
                    {formSettings.map((o) => {
                      return (
                        <Field key={o.field} name={o.field}>
                          {(fieldProps: FieldProps) => {
                            const { field, meta } = fieldProps
                            return (
                              <div className={`${o.size} px-1 ${o.height}`}>
                                <Input
                                  type={o.type}
                                  label={o.label}
                                  options={o.options}
                                  setFieldValue={props.setFieldValue}
                                  disabled={
                                    o.disabled ||
                                    (field.name !== 'status' &&
                                      [9, 99].includes(order.status))
                                  }
                                  {...field}
                                />
                                {meta.touched && meta.error && meta.error}
                              </div>
                            )
                          }}
                        </Field>
                      )
                    })}
                  </div>
                  <div className="p-1">
                    <div
                      className="border border-solid border-gray-300 relative rounded p-4 text-sm my-2 cursor-pointer hover:bg-gray-100 active:bg-gray-300 select-none"
                      onClick={clickImpositionData}
                    >
                      <h3 className="text-sm absolute bg-white rounded top-0 left-0 -mt-2 ml-2 text-gray-500 font-bold">
                        面付け用基本データ
                      </h3>
                      {impositionStr}
                      {impositionCopied && (
                        <div className="absolute right-0 bottom-0 text-xs border border-green-500 rounded bg-white text-green-500 px-2 mb-1 mr-1">
                          コピーしました
                        </div>
                      )}
                    </div>
                    <div>
                      シート数(自動算出) = {sheetNum}シート{' '}
                      <a
                        className="text-blue-500 hover:text-blue-700"
                        href="https://test.hitlabel.jp/simu/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        [参考]
                      </a>
                    </div>
                    <QR order_id={order.order_id} />
                  </div>
                  {estimateData && estimateData.child ? (
                    <>
                      <h2 className="text-xl font-bold py-4">見積仕様</h2>
                      <div>
                        <span>見積番号: </span>
                        <span>
                          <a
                            href={`/estimates/${estimateData.estimate.estimateNumber}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-700 hover:underline hover:text-blue-900"
                          >
                            {estimateData.estimate.estimateNumber}
                          </a>
                        </span>
                      </div>
                      <div>
                        <span>見積子番号: </span>
                        <span>{estimateData.child.no}</span>
                      </div>
                      <div>
                        <span>パターン名: </span>
                        <span>{estimateData.child.name}</span>
                      </div>
                      <div>
                        <span>請求金額: </span>
                        <span>
                          {estimateData.child.amount_with_tax}円(内消費税:
                          {estimateData.child.tax}円)
                        </span>
                      </div>
                      <h3 className="text-lg font-bold py-2">仕様</h3>
                      <div>
                        <span className="whitespace-pre">
                          {estimateData.child.main.specification}
                        </span>
                      </div>
                    </>
                  ) : null}
                  {order.repeat_id !== 0 && (
                    <section className="p-1">
                      <h3 className="text-lg font-bold py-2">
                        リピート履歴
                        <small>(リピート回数:{repeatHistories.length})</small>
                      </h3>
                      <table className="text-sm">
                        <thead>
                          <tr>
                            <th className="border px-2">注文番号</th>
                            <th className="border px-2">注文日</th>
                            <th className="border px-2">前回から変更</th>
                          </tr>
                        </thead>
                        <tbody>
                          {repeatHistories.map((r: any) => {
                            return (
                              <tr key={r.order_id}>
                                <td className="border px-2 py-1">
                                  <a
                                    href={`/orders/${r.order_id}`}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className="text-blue-500 visited:text-purple-700 hover:text-blue-800 active:text-blue-900"
                                  >
                                    {r.order_id}
                                  </a>
                                </td>
                                <td className="border px-2 py-1">
                                  {dayjs(r.ordered_at).format('YYYY/MM/DD')}
                                </td>
                                <td className="border px-2 py-1 text-center">
                                  {r.is_changed_from_last_order ? (
                                    <span className="text-red-700">●</span>
                                  ) : (
                                    <span>--</span>
                                  )}
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </section>
                  )}
                  <Controller
                    onClick={(name: string) => {
                      setPopup(name)
                    }}
                    products={products}
                    user={user}
                    onDownloadInvoice={handleDownloadInvoice}
                    onIssueReceipt={handleIssueReceipt}
                    disabled={[9, 99].includes(order.status) === true}
                  />
                </Form>
              )}
            </Formik>
            <div className="flex-1 break-all">
              <SampleImage src={sampleImage} onDrop={handleDrop} />
              <UserData orderId={order.order_id} files={order.attachments} />
              <PurchaseTable purchase={purchase.purchase} />
              <UserTable user={user} />
            </div>
          </div>
          <hr className="my-4" />
          <section id="section-yupack">
            <Yupack order={order} />
          </section>
          <section id="section-memo">
            <Memo orderId={order.order_id} />
          </section>
          <hr className="my-4" />
          <section id="section-send-mail-log">
            <SendMailLog log={sendmailLog} />
          </section>
        </div>
        {popup === 'datachecked' && (
          <Datachecked onClose={closePopup} onSubmit={sendDatachecked} />
        )}
        {popup === 'shipment' && (
          <Shipment onClose={closePopup} onSubmit={sendShipped} />
        )}
        {popup === 'doneAgain' && (
          <DoneAgain onClose={closePopup} onSubmit={sendOrderConfirmation} />
        )}
        {popup === 'shippedMultiple' && (
          <ShippedMultiple onClose={closePopup} onSubmit={setShippedMultiple} />
        )}
        {popup === 'deliveredMultiple' && (
          <DeliveredMultiple
            onClose={closePopup}
            onSubmit={setDeliveredMultiple}
          />
        )}
        {popup === 'deliveryInfo' && (
          <DeliveryInfo
            onClose={closePopup}
            onSubmit={changeDeliveryInfo}
            purchase={purchase.purchase}
          />
        )}
        {popup === 'customerInfo' && (
          <UserInfo
            onClose={closePopup}
            onSubmit={changeCustomerInfo}
            user={user}
          />
        )}
        {popup === 'sendMail' && (
          <SendMail
            onClose={closePopup}
            onSubmit={sendMail}
            user={user}
            order={order}
            purchase={purchase}
            products={products}
          />
        )}
      </div>
      <PrintPage
        order={order}
        user={user}
        purchase={purchase.purchase}
        products={products}
      />
    </>
  )
}

const prepareRepeatHistories = (order: any) => {
  if (order.repeat_id === 0) {
    return
  }
  order.repeatHistories.sort((prev: any, cur: any) => {
    const prevTime = new Date(prev.ordered_at).getTime()
    const curTime = new Date(cur.ordered_at).getTime()
    return curTime - prevTime
  })
  return order.repeatHistories
}

export default Order
